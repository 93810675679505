import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { setActiveStep } from '@/redux/checkout/checkoutReducer';
// import useAllowShipping from '../useAllowShipping';

const useCheckoutRouter = (from) => {
  const router = useRouter();
  const checkout = useSelector((state) => state.checkout);
  // const allowCP = useSelector((state) => state.cart?.mendoza_zipcodes);
  // const shippingAllow = useAllowShipping();

  const dispatch = useDispatch();

  const location = {
    cart: () => {
      if (checkout.logged) {
        router.push('/checkout');
      } else {
        router.push('/checkout/mail-login');
      }
    },
    login: () => {
      router.back();
    },
    mailLogin: () => {
      router.push('/checkout');
    },
    LoginCheckout: () => {
      const redirectTo = window.localStorage.getItem('redirectTo');
      router.push(redirectTo);
    },
    review: (arg) => {
      if (!arg) {
        router.push('/checkout/finish');
      } else {
        dispatch(setActiveStep('Review'));
        if (arg === 'changePersonalInfo') {
          if (checkout.logged || checkout.newUser) {
            router.push('/checkout');
          } else {
            window.localStorage.setItem('redirectTo', '/checkout');
            router.push('/checkout/login-checkout');
          }
        }
        if (arg === 'changePaymentInfo') {
          router.push('/checkout');
        }
        if (arg === 'changeShippingAddress') {
          router.push('/checkout');
        }
      }
    }
  };

  return location[from];
};

export default useCheckoutRouter;
